import React from 'react';

import { Help as HelpIcon } from '@material-ui/icons';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Box } from '@vk-hr-tek/ui/Box';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { List, ListItem } from '@vk-hr-tek/ui/List';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';
import { UsersIcon } from '@vk-hr-tek/ui/icons';
import { useSelector } from '@vk-hr-tek/app/app/hooks';
import { Accordion } from '@vk-hr-tek/ui/Accordion';
import { Divider } from '@vk-hr-tek/ui/Divider';

import { selectUser } from '../../../slice';
import { Attorney } from '../Attorney';
import { AttorneySettingsNotification } from '../AttorneySettingsNotification';

export const Roles = () => {
  const user = useSelector(selectUser);
  const isDesktop = useIsDesktop();

  return (
    <Box
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
      overflow="hidden"
    >
      <Accordion
        title={
          <Box gap="8" display="flex">
            <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
              Роль и МЧД
            </Typography>
            <Tooltip
              title={'МЧД - машиночитаемая доверенность'}
              placement={'bottom'}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
              >
                <HelpIcon color="disabled" fontSize="small" />
              </Box>
            </Tooltip>
          </Box>
        }
        defaultExpanded
        detailsWithoutPaddings
      >
        {user &&
          user.groups.map((group, index) => (
            <>
              <Box p="24" display="flex" flexDirection="column" gap="16">
                <Box mb="8">
                  <Typography
                    variant={isDesktop ? 'h6' : 'subtitle1'}
                    color="text.light.primary"
                  >
                    {group.name}
                  </Typography>
                </Box>

                <Box>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <Box mb={{ xs: '8', md: '24' }}>
                        <Typography
                          variant={isDesktop ? 'body2' : 'body3'}
                          color="text.light.tertirary"
                        >
                          Роли
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        gap="8"
                        mb={{ xs: '16' }}
                      >
                        {group.roles.map(({ id, name, profiles }) => (
                          <Tooltip
                            disabled={!profiles}
                            title={
                              <Box fontSize={12}>
                                <Box>
                                  <Typography
                                    color="inherit"
                                    variant="subtitle2"
                                  >
                                    Профили:
                                  </Typography>
                                </Box>
                                <Box mt="8" ml="24">
                                  <List listStyleType="disc">
                                    {profiles?.map((profile) => (
                                      <ListItem key={profile.id}>
                                        {profile.name}
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Box>
                            }
                            placement="right"
                          >
                            <Box key={id}>
                              <ColoredChip
                                label={name}
                                color="gray"
                                endIcon={
                                  !!profiles && <UsersIcon fontSize="inherit" />
                                }
                              />
                            </Box>
                          </Tooltip>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <Box mb={{ xs: '8', md: '0' }}>
                        <Typography
                          variant={isDesktop ? 'body2' : 'body3'}
                          color="text.light.tertirary"
                        >
                          Список МЧД
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Box display="flex" flexWrap="wrap" gap="16">
                        {!!group.attorneys.length
                          ? group.attorneys.map((attorney) => (
                              <Box
                                flexBasis={
                                  isDesktop ? 'calc(50% - 8px)' : '100%'
                                }
                                flexGrow={0}
                                flexShrink={0}
                              >
                                <Attorney key={attorney.id} {...attorney} />
                              </Box>
                            ))
                          : '-'}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {!!group?.attorneys.filter(
                  (attorney) =>
                    attorney.status === 'signed' ||
                    attorney.status === 'revoking',
                ).length && (
                  <AttorneySettingsNotification companyId={group.id} />
                )}
              </Box>
              {user.groups.length - 1 !== index && <Divider />}
            </>
          ))}
      </Accordion>
    </Box>
  );
};
