import React, { useEffect } from 'react';

import HelpIcon from '@material-ui/icons/Help';
import FileCopyIcon from '@material-ui/icons/FilterNone';

import { useDispatch, useSelector } from '@vk-hr-tek/app/app/hooks';
import {
  generate1CToken,
  get1CTokenStatus,
  reset1CToken,
  selectToken1C,
  selectToken1CStatus,
} from '@vk-hr-tek/app/user';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { IconButton } from '@vk-hr-tek/ui/IconButton';

export const TokenGeneration = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const token1CStatus = useSelector(selectToken1CStatus);
  const token1C = useSelector(selectToken1C);

  const subtitleVariant = isDesktop ? 'subtitle1' : 'subtitle2';
  const textVariant = isDesktop ? 'body2' : 'body3';

  const handleClick = () => {
    if (token1CStatus !== 'loading') {
      dispatch(generate1CToken());
    }
  };

  useEffect(() => {
    if (token1CStatus !== 'loading') dispatch(get1CTokenStatus());
  }, [dispatch, token1CStatus]);

  useEffect(() => {
    return () => {
      dispatch(reset1CToken());
    };
  }, [dispatch]);

  return (
    <Box
      gap="16"
      display="flex"
      flexDirection="column"
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
      paddingX="24"
      paddingY="20"
    >
      <Box display="flex" flexDirection="column" gap={isDesktop ? '16' : '8'}>
        <Box gap="8" display="flex" alignItems="center">
          <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
            Токен доступа для 1С
          </Typography>
        </Box>
        <Typography variant={textVariant} color="text.light.tertirary">
          Для работы в модуле 1С сгенерируйте токен. Срок действия токена —
          90 дней
        </Typography>
      </Box>
      <Paper>
        <Box p={isDesktop ? '20' : '16'}>
          <Box
            display={isDesktop ? 'flex' : 'block'}
            flexDirection="column"
            justifyContent="space-between"
            gap="16"
          >
            <Box>
              <Box pb="8">
                <Typography variant={subtitleVariant}>
                  Генерация токена
                </Typography>
              </Box>
              <Typography variant={textVariant} color="text.light.tertirary">
                После генерации нового токена нужно будет заново авторизоваться
                во всех 1С базах, где вы работаете с КЭДО
              </Typography>
            </Box>

            {token1C && (
              <Box
                p="12"
                radius="m"
                bgcolor="bg.greyscale.primary"
                display="flex"
                gap="8"
              >
                <Box display="flex" flexDirection="column" gap="8">
                  <Box display="flex" alignItems="center">
                    <Box mr="8">Токен</Box>
                    <Tooltip title="Токен виден, пока вы не обновите страницу (по требованиям безопасности)">
                      <HelpIcon color="disabled" fontSize="small" />
                    </Tooltip>
                  </Box>
                  <Box>
                    <EllipsisText wordBreak="break-all" rows={1}>
                      <Typography variant="body3" color="text.light.tertirary">
                        {token1C}
                      </Typography>
                    </EllipsisText>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <IconButton
                    type="button"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(token1C);
                    }}
                  >
                    <FileCopyIcon color="primary" fontSize="medium" />
                  </IconButton>
                </Box>
              </Box>
            )}

            <Box mt={!isDesktop ? '8' : '0'}>
              <Button
                onClick={handleClick}
                fullWidth={!isDesktop}
                size="small"
                loading={token1CStatus === 'loading'}
              >
                {(token1CStatus === 'idle' || token1CStatus === 'failed') &&
                  'Сгенерировать'}
                {token1CStatus === 'complete' && 'Сгенерировать еще раз'}
              </Button>
            </Box>
          </Box>

          {token1CStatus === 'failed' && (
            <Box mt="8">
              <Typography color="accent.text.error" variant="body3">
                Во время генерации токена произошла ошибка. Попробуйте еще раз.
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
