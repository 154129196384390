import React from 'react';

import InputMask from 'react-input-mask';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { Preloader } from '../common';

import useStyles from './SuggestedInput.styles';

interface TextFieldMaskedProps {
  mask: string;
  value?: string;
  loading?: boolean;
}

export const TextFieldMasked = ({
  mask,
  value,
  name,
  error,
  helperText,
  loading,
  ...rest
}: TextFieldMaskedProps & TextFieldProps) => {
  const classes = useStyles();

  return (
    <InputMask
      mask={mask}
      name={name}
      value={value}
      onChange={rest.onChange}
      onFocus={rest.onFocus}
      onBlur={rest.onBlur}
      onKeyPress={rest.onKeyPress}
      disabled={rest.disabled}
    >
      {() => (
        <TextField
          inputProps={{ ref: rest?.inputProps?.ref }}
          name={name}
          classes={{ root: classes.inputRoot }}
          autoComplete="off"
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          placeholder={rest.placeholder}
          {...(loading
            ? {
                InputProps: {
                  endAdornment: <Preloader />,
                },
              }
            : {})}
        />
      )}
    </InputMask>
  );
};
