import React from 'react';

import { useSelector } from '@vk-hr-tek/app/app/hooks';
import { Accordion } from '@vk-hr-tek/ui/Accordion';
import { Box } from '@vk-hr-tek/ui/Box';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Divider } from '@vk-hr-tek/ui/Divider';

import { selectEditPermission, selectManagebleUnits } from '../../../slice';

import { Substitutes } from '.';

export const SubstitutesList = () => {
  const managebleUnits = useSelector(selectManagebleUnits);
  const hasEditPermission = useSelector(selectEditPermission);
  const isDesktop = useIsDesktop();

  const filteredManagebleUnits = managebleUnits.filter(
    ({ eventTypeGroup }) => isDesktop || eventTypeGroup.length,
  );

  if (!hasEditPermission) {
    return null;
  }

  return (
    <Box
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
      overflow="hidden"
    >
      <Accordion
        title={
          <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
            Заместители
          </Typography>
        }
        defaultExpanded
        detailsWithoutPaddings
      >
        {filteredManagebleUnits.map((managableUnit, index, arr) => (
          <React.Fragment key={managableUnit.company.id}>
            <Substitutes
              hasMultiEmployees={managableUnit.hasMultiEmployees}
              managableUnit={managableUnit}
            />
            {arr.length - 1 !== index && <Divider />}
          </React.Fragment>
        ))}
      </Accordion>
    </Box>
  );
};
