import React from 'react';

import { Help as HelpIcon } from '@material-ui/icons';

import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Box } from '@vk-hr-tek/ui/Box';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { useSelector } from '@vk-hr-tek/app/app/hooks';
import { selectUser } from '@vk-hr-tek/app/user';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Divider } from '@vk-hr-tek/ui/Divider';

import { EmployeeInfo } from '../EmployeeInfo';

export const GeneralInfo = () => {
  const user = useSelector(selectUser);
  const isDesktop = useIsDesktop();

  const tooltipPlacement = isDesktop ? 'right' : 'bottom';

  if (!user) {
    return null;
  }

  return (
    <Box
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
    >
      <Box gap="24" display="flex" flexDirection="column">
        <Box
          gap="16"
          display="flex"
          flexDirection="column"
          paddingX="24"
          paddingY="20"
        >
          <Typography
            variant={isDesktop ? 'h6' : 'subtitle1'}
            color="text.light.primary"
          >
            Общая информация
          </Typography>
          <Box
            gap={isDesktop ? '24' : '8'}
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
          >
            <Typography variant="body2" color="text.light.tertirary">
              Электронная подпись
            </Typography>
            <Box gap="12" display="flex" flexDirection="column">
              <Box gap="8" display="flex" flexDirection="column">
                <Box gap="4" display="flex">
                  <Typography variant="subtitle1" color="text.light.primary">
                    {user.certificate.type}
                  </Typography>
                  <Tooltip
                    title={user.certificate.description}
                    placement={tooltipPlacement}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                    >
                      <HelpIcon color="disabled" fontSize="small" />
                    </Box>
                  </Tooltip>
                </Box>

                <Typography variant="body2" color="text.light.primary">
                  {user.certificate.activity}
                </Typography>
              </Box>

              {!!user.ukepCertificates?.length &&
                user.ukepCertificates.map((certificate) => (
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                    gap="8"
                  >
                    <Box display="flex" alignItems="center" gap="8">
                      <Typography
                        variant="subtitle1"
                        color="text.light.primary"
                      >
                        {certificate.type}
                      </Typography>
                      <Tooltip
                        title={certificate.description}
                        placement={tooltipPlacement}
                      >
                        <HelpIcon color="disabled" fontSize="small" />
                      </Tooltip>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="4"
                      width="100%"
                    >
                      <Typography variant="body2" color="text.light.primary">
                        {certificate.activity}
                      </Typography>
                      <Typography variant="body2" color="text.light.primary">
                        {certificate.issuedBy}
                      </Typography>
                      <Typography variant="body2" color="text.light.primary">
                        <EllipsisText>{certificate.owner}</EllipsisText>
                      </Typography>
                      <Typography variant="body2" color="text.light.primary">
                        <EllipsisText wordBreak="break-all">
                          {certificate.serialNumber}
                        </EllipsisText>
                      </Typography>
                      <Typography variant="body2" color="text.light.tertirary">
                        {certificate.companyName}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {user.employees.map((employee) => (
        <Box key={employee.id}>
          <Divider />
          <EmployeeInfo employee={employee} />
        </Box>
      ))}
    </Box>
  );
};
