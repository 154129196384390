import {
  IsNotEmpty,
  IsOptional,
  IsString,
  IsIn,
  IsArray,
  ArrayMinSize,
  ArrayMaxSize,
  ArrayUnique,
  IsUUID,
} from 'class-validator';

export class CreateEventTypeDto {
  @IsUUID(undefined, { message: 'Вы указали неверную компанию' })
  @IsNotEmpty({ message: 'Вы забыли выбрать компанию' })
  companyId: string;

  @IsString({ message: 'Вы ввели неверное название' })
  @IsNotEmpty({ message: 'Вы забыли ввести название' })
  name: string;

  @IsIn(['company', 'employee', 'company_employee', 'employee_company'], {
    message: 'Вы ввели неверный тип подписания',
  })
  @IsString({ message: 'Вы ввели неверный тип подписания' })
  @IsNotEmpty({ message: 'Вы забыли выбрать тип подписания' })
  pattern: string;

  @IsString({ each: true, message: 'Названия документов должны быть строками' })
  @IsNotEmpty({
    each: true,
    message: 'Названия документов не могут быть пустыми',
  })
  @ArrayMinSize(1, {
    message: 'Нужно ввести не менее одного названия документа',
  })
  @ArrayMaxSize(3, { message: 'Нельзя ввести более 3 названий документов' })
  @ArrayUnique({ message: 'Названия документов не могут повторяться' })
  @IsArray({ message: 'Вы ввели неверные названия документов' })
  documents: string[];

  @IsOptional()
  @IsUUID(undefined, { message: 'Вы выбрали неверную роль' })
  @IsNotEmpty({ message: 'Вы забыли выбрать роль' })
  signatureGroupId: string;

  @IsOptional()
  @IsString({ each: true, message: 'Вы выбрали неверную группу работников' })
  customType: string;

  @IsUUID(undefined, { message: 'Вы выбрали неверную роль' })
  @IsNotEmpty({ message: 'Вы забыли выбрать роль' })
  personUpload: string;

  @IsOptional()
  @IsUUID(undefined, { message: 'Вы выбрали неверную роль' })
  @IsNotEmpty({ message: 'Вы забыли выбрать роль' })
  personAccept: string;
}
