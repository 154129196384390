import React, { useCallback } from 'react';

import { useDispatch, useSelector } from '@vk-hr-tek/app/app/hooks';
import {
  cancelTelegramSubscription,
  selectTelegramSubscriptionStatus,
  selectUser,
  subscribeOnTelegram,
} from '@vk-hr-tek/app/user';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

export const Notifications = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const user = useSelector(selectUser);
  const telegramSubscriptionStatus = useSelector(
    selectTelegramSubscriptionStatus,
  );

  const isTelegramSubscriptionsEnabled =
    (telegramSubscriptionStatus === 'idle' && user?.notifications.telegram) ||
    telegramSubscriptionStatus === 'enabled' ||
    telegramSubscriptionStatus === 'disablingInProgress';

  const handleCancelSubscribtion = useCallback(
    () => dispatch(cancelTelegramSubscription()),
    [dispatch],
  );

  const handleSubsctibe = useCallback(
    () => dispatch(subscribeOnTelegram()),
    [dispatch],
  );

  const handleTelegramSubscribtion = useCallback(() => {
    if (user?.notifications?.telegram) {
      handleCancelSubscribtion();
    } else {
      handleSubsctibe();
    }
  }, [
    user?.notifications?.telegram,
    handleCancelSubscribtion,
    handleSubsctibe,
  ]);

  if (!user) {
    return null;
  }

  return (
    <Box
      gap="16"
      display="flex"
      flexDirection="column"
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
      paddingX={isDesktop ? '24' : '16'}
      paddingY={isDesktop ? '20' : '16'}
    >
      <Box>
        <Typography
          variant={isDesktop ? 'h6' : 'subtitle1'}
          color="text.light.primary"
        >
          Уведомления
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" color="text.light.tertirary">
          Все уведомления мы отправляем вам в смс на номер телефона{' '}
          <Typography
            variant="body2"
            color="text.light.primary"
            component="span"
          >
            {user.phone}
          </Typography>
          {user.employees[0].email && (
            <>
              {' '}
              и корпоративную почту{' '}
              <Typography
                variant="body2"
                color="text.light.primary"
                component="span"
              >
                {user.employees[0].email}
              </Typography>
            </>
          )}
        </Typography>
      </Box>

      {window.REACT_APP_VKHRTEK_TELEGRAM_SUBSCRIPTION_ENABLED && (
        <Paper>
          <Box
            p={isDesktop ? '20' : '16'}
            display="flex"
            flexDirection="column"
          >
            <Box mb="16">
              <Box pb="8">
                <Typography variant="subtitle1" color="text.light.primary">
                  Telegram
                </Typography>
              </Box>
              <Typography variant="body2" color="text.light.tertirary">
                {isTelegramSubscriptionsEnabled ? (
                  <>
                    Если вы больше не хотите получать уведомления в Telegram,
                    отключите их
                  </>
                ) : (
                  <>Чтобы получать уведомления в Telegram, подключите бота</>
                )}
              </Typography>
            </Box>

            <Box>
              {telegramSubscriptionStatus === 'idle' && (
                <Button
                  onClick={handleTelegramSubscribtion}
                  size="small"
                  fullWidth={!isDesktop}
                  variant={
                    user.notifications.telegram ? 'quaternary' : 'primary'
                  }
                >
                  {user.notifications.telegram ? 'Отключить' : 'Подключить'}
                </Button>
              )}
              {(telegramSubscriptionStatus === 'enablingInProgress' ||
                telegramSubscriptionStatus === 'disablingInProgress') && (
                <Button
                  variant={
                    telegramSubscriptionStatus === 'enablingInProgress'
                      ? 'primary'
                      : 'quaternary'
                  }
                  size="small"
                  loading
                />
              )}
              {telegramSubscriptionStatus === 'enabled' && (
                <Button
                  onClick={handleCancelSubscribtion}
                  size="small"
                  variant="quaternary"
                >
                  Отключить
                </Button>
              )}
              {telegramSubscriptionStatus === 'disabled' && (
                <Button onClick={handleSubsctibe} size="small">
                  Подключить
                </Button>
              )}
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
};
