import React, { useState, useCallback, useEffect } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import {
  ArrowBackRounded as ArrowBackRoundedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import { SizeMe } from 'react-sizeme';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Logger } from '@vk-hr-tek/core/logger';

import { Box } from '../../Box';
import { CircularProgress } from '../../CircularProgress';
import { Button } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup';

import { PreviewButtons } from './Buttons';
import { useStyles } from './Preview.styles';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

interface PreviewPdfProps {
  url: string;
  shape: 'auto' | 'square';
  onClickPrint?: () => void;
  onOpen?: () => void;
  onClickDownload?: () => Promise<
    Blob | { file: Promise<Blob>; filename?: string }
  >;
  onLoadSuccess?: () => void;
  withCollapsableFooter?: boolean;
}

const options = {
  isEvalSupported: false,
};

export const PreviewPdf = ({
  url,
  shape,
  onClickPrint,
  onOpen,
  onClickDownload,
  onLoadSuccess,
  withCollapsableFooter,
}: PreviewPdfProps) => {
  const classes = useStyles();

  const [pagesCount, setPagesCount] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [nextPageNumber, setNextPageNumber] = useState<number>(1); // Prevent jumps when page loads
  const [isLoaded, setIsLoaded] = useState(false);
  const logger = useInject(Logger);

  useEffect(() => {
    setPageNumber(1);
    setNextPageNumber(1);
  }, [url]);

  const handleOpen = useCallback(() => onOpen && onOpen(), [onOpen]);
  const handleLoadSuccess = useCallback(
    ({ numPages }) => {
      setPagesCount(numPages);
      setIsLoaded(true);
      onLoadSuccess?.();
    },
    [setPagesCount, setIsLoaded, onLoadSuccess],
  );
  const handleSetPage = useCallback(
    () => setPageNumber(nextPageNumber),
    [nextPageNumber, setPageNumber],
  );
  const handlePrev = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setNextPageNumber((x) => Math.max(1, x - 1));
    },
    [setNextPageNumber],
  );
  const handleNext = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setNextPageNumber((x) => Math.min(pagesCount ?? 0, x + 1));
    },
    [setNextPageNumber, pagesCount],
  );

  const handleLoadError = (error: Error) => {
    logger.error(error, {
      tags: {
        vkdoc_error_type: 'pdf',
      },
    });
  };

  return (
    <SizeMe>
      {({ size }) => (
        <div
          className={classNames(
            classes.root,
            classes.collapseWrapper,
            'aqa_file_preview_pdf',
          )}
        >
          {shape === 'square' && <div className={classes.square} />}
          <Box onClick={handleOpen}>
            <Document
              options={options}
              onLoadError={handleLoadError}
              onSourceError={handleLoadError}
              className={classNames(
                classes.document,
                shape === 'square' && classes.document_square,
              )}
              file={url}
              error={
                <Box p="32" color="text.light.secondary" textAlign="center">
                  Ошибка при загрузке файла
                </Box>
              }
              loading={
                <Box display="flex" justifyContent="center" p="32">
                  <CircularProgress size={50} />
                </Box>
              }
              onLoadSuccess={handleLoadSuccess}
            >
              {[
                <Page
                  key={pageNumber}
                  width={size.width === null ? 1 : size.width}
                  pageNumber={pageNumber}
                  renderAnnotationLayer
                />,
                nextPageNumber !== pageNumber && (
                  <Page
                    className={classes.hiddenPage}
                    key={nextPageNumber}
                    width={size.width === null ? 1 : size.width}
                    pageNumber={nextPageNumber}
                    onLoadSuccess={handleSetPage}
                    renderAnnotationLayer
                  />
                ),
              ]}
            </Document>
          </Box>
          {pagesCount !== null && pagesCount > 1 && (size.width ?? 0) > 240 && (
            <div className={classes.pagination}>
              <ButtonGroup
                variant="contained"
                size="small"
                onClick={(event) => event.stopPropagation()}
              >
                <Button
                  disabled={nextPageNumber <= 1}
                  onClick={handlePrev}
                  icon={<ArrowBackRoundedIcon />}
                />
                <Button disabled>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={38}
                  >
                    {pageNumber}/{pagesCount}
                  </Box>
                </Button>
                <Button
                  disabled={nextPageNumber >= pagesCount}
                  onClick={handleNext}
                  icon={<ArrowForwardRoundedIcon />}
                />
              </ButtonGroup>
            </div>
          )}
          {(onClickDownload || onClickPrint) && isLoaded && (
            <PreviewButtons
              pdf
              onClickDownload={onClickDownload}
              withCollapsableFooter={withCollapsableFooter}
              onClickPrint={onClickPrint}
            />
          )}
        </div>
      )}
    </SizeMe>
  );
};
