import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsMobile } from '@vk-hr-tek/ui/hooks';
import { selectAuthInstance } from '@vk-hr-tek/app/auth/slice';

import { useSelector } from '@app/hooks';

import { useLogout } from '../../../auth';
import { Page } from '../../ui/page';

export const CertificateManualVerifying = () => {
  const logout = useLogout();
  const isMobile = useIsMobile();

  const authInstance = useSelector(selectAuthInstance);

  return (
    <Page title="Ручная обработка ошибки" theme="notification">
      <Box mb="16">
        <Typography
          variant={isMobile ? 'body3' : 'body1'}
          color="text.light.tertirary"
        >
          Идет ручная обработка ошибки при проверке паспортных данных. Вы можете
          обратиться в отдел кадров вашей компании для проверки актуальности
          данных.
        </Typography>
      </Box>
      <Typography
        variant={isMobile ? 'body3' : 'body1'}
        color="text.light.tertirary"
      >
        Если данные актуальные, вам необходимо написать о проблеме на&nbsp;
        <Link href="mailto:support@hrtek.ru" size="small" variant="simple">
          support@hrtek.ru
        </Link>
        , мы обязательно вам поможем
      </Typography>
      {authInstance.auth_type === 'pass' && (
        <Box mt="2">
          <Link onClick={logout} size="small" variant="tertiary">
            Выход к QR-коду
          </Link>
        </Box>
      )}
    </Page>
  );
};
