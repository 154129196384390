import React, { useCallback } from 'react';

import { AppError } from '@vk-hr-tek/core/error/error';

import { Box } from '../../Box';
import { Typography } from '../../Typography';
import { IconButton } from '../../IconButton';
import { DocumentCopyIcon } from '../../icons';

interface SubmitErrorProps {
  submitError: AppError;
  withDescription?: boolean;
}

export const SubmitError = ({
  submitError,
  withDescription,
}: SubmitErrorProps) => {
  const handleCopy = useCallback(() => {
    if (submitError.traceId) {
      navigator.clipboard.writeText(submitError.traceId);
    }
  }, [submitError.traceId]);

  return (
    <Box>
      <Typography color="accent.text.error" variant="body3">
        {submitError && submitError.title
          ? submitError.title
          : 'Что-то пошло не так. Напишите в чат поддержки и сообщите код ошибки'}
      </Typography>
      {withDescription && submitError.description && (
        <Typography color="accent.text.error" variant="body3">
          {submitError.description}
        </Typography>
      )}
      {submitError?.traceId && (
        <Box display="flex" alignItems="center" mt="8" gap="8">
          <Typography color="text.light.tertirary" variant="body3">
            {`Код ошибки: ${submitError.traceId}`}
          </Typography>
          <IconButton type="button" size="small" onClick={handleCopy}>
            <DocumentCopyIcon color="primary" size="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
