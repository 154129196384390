import React from 'react';

import { Close } from '@material-ui/icons';

import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { NotificationActionTypeEnum } from '@vk-hr-tek/core/notifications/types';

import { useStyles } from './Notifications.styles';
import { NotificationAction } from './NotificationAction';

export interface NotificationContentOptions {
  title?: string;
  subtitle?: string;
  action?: {
    text: string;
    link?: string;
    actionType: NotificationActionTypeEnum;
  };
  text: JSX.Element | string;
  icon?: JSX.Element;
  handleClose: () => void;
}

export const NotificationContent = ({
  text,
  title,
  subtitle,
  action,
  icon,
  handleClose,
}: NotificationContentOptions) => {
  const classes = useStyles();

  return (
    <>
      <Box
        p="16"
        className={`${classes.content} aqa_informer`}
        radius={action ? ['l', 'l', '0', '0'] : 'l'}
        bgcolor="bg.dark.primary"
      >
        <Box>
          {title && (
            <Box mb="8" alignItems="center" display="flex" gap="16">
              {icon && <Box display="flex">{icon}</Box>}
              <Box flexGrow={1}>
                <Typography variant="subtitle2" color="text.dark.primary">
                  {title}
                </Typography>
              </Box>
              <Button
                size="medium"
                variant="tertiaryDark"
                onClick={handleClose}
                icon={<Close />}
              />
            </Box>
          )}
          {subtitle && (
            <Box mb="4" ml={icon ? '40' : '0'}>
              <Typography variant="caption" color="text.light.tertirary">
                {subtitle}
              </Typography>
            </Box>
          )}
          <Box alignItems="center" display="flex" gap="16">
            {icon && !title && <Box display="flex">{icon}</Box>}
            <Box flexGrow={1} ml={icon && title ? '40' : '0'}>
              <Typography variant="body3" color="text.dark.primary">
                {text}
              </Typography>
            </Box>
            {!title && (
              <Button
                size="medium"
                variant="tertiaryDark"
                onClick={handleClose}
                icon={<Close />}
              />
            )}
          </Box>
        </Box>
      </Box>

      {action && (
        <NotificationAction
          actionType={action.actionType}
          text={action.text}
          link={action.link}
        />
      )}
    </>
  );
};
