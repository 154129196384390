import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
  EntityState,
} from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { Filter } from '@vk-hr-tek/core/filter';
import {
  VacationAvailableDaysParams,
  VacationAvailableDaysResponse,
  VacationScheduleMyDeadlineResponse,
  VacationsService,
} from '@vk-hr-tek/app/app/gen/vacations';

import { ThunkExtra, ThunkValuesAndActions } from '@app/store';
import {
  AbsenceCompanyItem,
  AbsenceListGroup,
  AbsenceListResponse,
} from '@app/gen/absences';
import {
  CreateEventCompanyItem,
  CreateEventOptionsList,
  CreateEventResponse,
} from '@app/gen/events';

import { GetAbsenceListServiceDto, GetCompanyAbsencesListDto } from '../../dto';
import { AbsencesListService, AbsencesService } from '../../services';
import { AbsencesState } from '../../slice';
import { GetDeadlineDto } from '../../dto/get-deadline.dto';
import {
  CreateEventFromAbsenceDto,
  CreateEventFromButtonDto,
} from '../../dto/create-event.dto';
import { AbsencesRouter } from '../../types';
import {
  absencesAdapter,
  AbsencesWithRootState,
  completeLoading,
  setError,
  setEventCreationError,
  startLoading,
} from '../absences.state';

export const getAbsenceList = createAsyncThunk<
  AbsenceListGroup[],
  GetAbsenceListServiceDto,
  ThunkExtra
>(
  'absences/getAbsenceList',
  async ({ companyId, year }, { rejectWithValue, extra: { inject } }) => {
    const calendar = inject(Calendar);
    try {
      const result = await inject(AbsencesService).getAbsenceList({
        companyId,
        year,
      });

      await calendar.download(new Date(String(year)));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setCompanyFilters = createAction<Record<string, Filter[]>>(
  'absencesList/setCompanyFilters',
);

export const getCompanyAbsenceList = createAsyncThunk<
  AbsenceListResponse,
  GetCompanyAbsencesListDto,
  ThunkExtra
>(
  'absences/getCompanyAbsenceList',
  async (
    getCompanyAbsencesListDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    const calendar = inject(Calendar);
    const absencesListService = inject(AbsencesListService);

    const { year, companyId } = getCompanyAbsencesListDto;

    try {
      const state = (getState() as AbsencesWithRootState).absences;

      let filters = state.absenceList.filters;

      if (!filters[companyId]) {
        const awailableFilters =
          (await absencesListService.getCompanyAbsenceFilters(
            companyId,
          )) as Filter[];
        filters = { [companyId]: awailableFilters };
        dispatch(setCompanyFilters(filters));
      }

      const result = await absencesListService.getCompanyAbsencesList(
        getCompanyAbsencesListDto,
      );

      await calendar.download(new Date(String(year)));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAdditionalCompanyAbsenceItems = createAsyncThunk<
  AbsenceListResponse,
  ThunkValuesAndActions<GetCompanyAbsencesListDto>,
  ThunkExtra<AbsencesWithRootState>
>(
  'absences/getAdditionalCompanyAbsenceItems',
  async (
    { values, actions },
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().absences;
      const absencesListService = inject(AbsencesListService);

      let filters = state.absenceList.filters;

      if (!filters[values.companyId]) {
        const awailableFilters =
          (await absencesListService.getCompanyAbsenceFilters(
            values.companyId,
          )) as Filter[];
        filters = { [values.companyId]: awailableFilters };
        dispatch(setCompanyFilters(filters));
      }

      const result = await absencesListService.getCompanyAbsencesList(values);
      actions.resolve(null);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCompanies = createAsyncThunk<
  CreateEventCompanyItem[],
  undefined,
  ThunkExtra
>(
  'absences/getCompanies',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(AbsencesService).getCompanies();

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventFromAbsence = createAsyncThunk<
  CreateEventResponse,
  CreateEventFromAbsenceDto,
  ThunkExtra
>(
  'absences/createEvent',
  async (
    { employeeId, eventTypeId, attributes },
    { rejectWithValue, extra: { inject } },
  ) => {
    try {
      const result = await inject(AbsencesService).createEventFromAbsence({
        eventTypeId,
        employeeId,
        attributes,
      });

      inject<AbsencesRouter>(AbsencesRouter).goToCreatedEvent(result.event_id);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getDeadline = createAsyncThunk<
  VacationScheduleMyDeadlineResponse,
  GetDeadlineDto,
  ThunkExtra
>(
  'absences/getDeadline',
  async ({ companyId }, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(AbsencesService).getVacationScheduleDeadline({
        companyId,
      });

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAbsenceCompanies = createAsyncThunk<
  AbsenceCompanyItem[],
  undefined,
  ThunkExtra
>(
  'absences/getAbsenceCompanies',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(AbsencesListService).getAbsenceCompanies();

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const resetEventCreationError = createAction(
  'absences/resetEventCreationError',
);

export const getOptions = createAsyncThunk<
  { companyId: string; result: CreateEventOptionsList },
  string,
  ThunkExtra
>(
  'absences/createEvent/getOptions',
  async (companyId, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(AbsencesService).getCreateOptions();
      return {
        companyId,
        result,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventFromButton = createAsyncThunk<
  CreateEventResponse,
  ThunkValuesAndActions<CreateEventFromButtonDto>,
  ThunkExtra
>(
  'createEvent',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const absenceService = inject(AbsencesService);
      const result = await absenceService.createEvent(values);

      inject<AbsencesRouter>(AbsencesRouter).goToDetail(result.event_id);

      actions.resolve(null);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getVacationAvailableDays = createAsyncThunk<
  VacationAvailableDaysResponse,
  VacationAvailableDaysParams,
  ThunkExtra
>(
  'absences/getVacationAvailableDays',
  async (params, { rejectWithValue, extra: { inject } }) => {
    try {
      const vacations = inject(VacationsService);

      const result = await vacations.vacationAvailableDays({
        params,
      });

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const absencesReducers = (
  builder: ActionReducerMapBuilder<
    EntityState<AbsenceListGroup> & AbsencesState
  >,
) => {
  builder.addCase(getVacationAvailableDays.fulfilled, (state, action) => {
    state.availableDays.data = action.payload;
    state.availableDays.status = 'complete';
  });
  builder.addCase(getVacationAvailableDays.pending, (state) => {
    state.availableDays.status = 'loading';
  });
  builder.addCase(getVacationAvailableDays.rejected, (state) => {
    state.availableDays.status = 'failed';
  });
  builder.addCase(getAbsenceList.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(getAbsenceList.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.absenceList.items = payload;
  });

  builder.addCase(
    getAbsenceList.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        setError(state, { payload, error });
      }
    },
  );

  builder.addCase(setCompanyFilters, (state, action) => {
    state.absenceList.filters = action.payload;
  });

  builder.addCase(getCompanyAbsenceList.pending, (state) => {
    state.absenceList.status = 'loading';
  });
  builder.addCase(getCompanyAbsenceList.fulfilled, (state, { payload }) => {
    state.absenceList.status = 'complete';
    state.absenceList.items = payload.groups;
    state.absenceList.total = payload.total;
    state.currentIds = payload.groups.map(({ employee }) => employee.id);

    absencesAdapter.upsertMany(state, payload.groups);
  });
  builder.addCase(getCompanyAbsenceList.rejected, (state) => {
    state.absenceList.status = 'failed';
  });

  builder.addCase(getAdditionalCompanyAbsenceItems.pending, (state) => {
    state.absenceList.additionalStatus =
      state.absenceList.additionalStatus === 'failed'
        ? 'loadingAfterFail'
        : 'loading';
  });

  builder.addCase(
    getAdditionalCompanyAbsenceItems.fulfilled,
    (state, { payload }) => {
      state.absenceList.additionalStatus = 'complete';
      state.absenceList.total = payload.total;
      state.absenceList.items = [...state.absenceList.items, ...payload.groups];
      state.currentIds = [
        ...state.currentIds,
        ...payload.groups.map(({ employee }) => employee.id),
      ];

      absencesAdapter.upsertMany(state, payload.groups);
    },
  );

  builder.addCase(getAdditionalCompanyAbsenceItems.rejected, (state) => {
    state.absenceList.additionalStatus = 'failed';
  });

  builder.addCase(getCompanies.pending, (state) => {
    state.companies.status = 'loading';
  });
  builder.addCase(getCompanies.fulfilled, (state, { payload }) => {
    state.companies.status = 'complete';
    state.companies.items = payload;
  });
  builder.addCase(getCompanies.rejected, (state) => {
    state.companies.status = 'failed';
  });

  builder.addCase(getAbsenceCompanies.pending, (state) => {
    state.absenceCompanies.status = 'loading';
  });
  builder.addCase(getAbsenceCompanies.fulfilled, (state, { payload }) => {
    state.absenceCompanies.status = 'complete';
    state.absenceCompanies.items = payload;
  });
  builder.addCase(getAbsenceCompanies.rejected, (state) => {
    state.absenceCompanies.status = 'failed';
  });

  builder.addCase(getDeadline.pending, (state) => {
    state.deadline.status = 'loading';
    state.deadline.info = {};
  });
  builder.addCase(getDeadline.fulfilled, (state, { payload }) => {
    state.deadline.status = 'complete';
    state.deadline.info = payload;
  });
  builder.addCase(getDeadline.rejected, (state) => {
    state.deadline.status = 'failed';
    state.deadline.info = {};
  });

  builder.addCase(resetEventCreationError, (state) => {
    state.eventCreationError = null;
  });

  builder.addCase(
    createEventFromAbsence.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        setEventCreationError(state, { payload, error });
      }
    },
  );

  builder.addCase(getOptions.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
    state.canCreateEvent = false;
  });
  builder.addCase(getOptions.fulfilled, (state, { payload }) => {
    state.creation.status = 'complete';
    state.creation.error = null;
    state.creation.options = payload.result.options;
    state.creation.items = payload.result.items;
    state.canCreateEvent =
      payload.result.items.findIndex(
        (item) => item.company_id === payload.companyId,
      ) !== -1
        ? true
        : false;
  });
  builder.addCase(getOptions.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
    state.canCreateEvent = false;
  });

  builder.addCase(createEventFromButton.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(createEventFromButton.fulfilled, (state) => {
    state.creation.status = 'complete';
    state.creation.error = null;
  });
  builder.addCase(
    createEventFromButton.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
};
