import React, { useEffect } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { selectAuthInstance } from '@vk-hr-tek/app/auth/slice';

import { useDispatch, useSelector } from '@app/hooks';

import { useLogout } from '../../../auth';
import { Page } from '../../ui/page';
import { pollUser } from '../../slice';

export const CertificateCreation = () => {
  const dispatch = useDispatch();
  const logout = useLogout();

  const authInstance = useSelector(selectAuthInstance);

  useEffect(() => {
    dispatch(pollUser());
  }, [dispatch]);

  return (
    <Page theme="notification">
      <Box mb="16">
        <Typography variant="h5" align="center">
          Идет регистрация
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="body3" color="text.light.secondary">
          Это может занять несколько минут, но мы постараемся побыстрее
        </Typography>
      </Box>
      {authInstance.auth_type === 'pass' && (
        <Box mt="20" display="flex" justifyContent="center">
          <Link onClick={logout} size="small" variant="tertiary">
            Выход к QR-коду
          </Link>
        </Box>
      )}
      <Box display="flex" justifyContent="center" pt="40">
        <CircularProgress size={50} />
      </Box>
    </Page>
  );
};
