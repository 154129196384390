import React, { useCallback } from 'react';

import { useSelector } from '@vk-hr-tek/app/app/hooks';
import { selectUser } from '@vk-hr-tek/app/user';
import { Avatar } from '@vk-hr-tek/ui/Avatar';
import { Box } from '@vk-hr-tek/ui/Box';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useInject } from '@vk-hr-tek/core/ioc';
import { UserApiService } from '@vk-hr-tek/app/user/services';

export const UserInfo = () => {
  const user = useSelector(selectUser);
  const isDesktop = useIsDesktop();
  const userApiService = useInject(UserApiService);

  const loadPhoto = useCallback(async () => {
    const res = await userApiService.getUserPhoto({ user_id: user?.id ?? '' });

    return res.file;
  }, [userApiService, user?.id]);

  if (!user) {
    return null;
  }

  return (
    <Box
      gap="16"
      display="flex"
      flexDirection={isDesktop ? 'row' : 'column'}
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
      paddingX="24"
      paddingY="20"
    >
      <Avatar size={isDesktop ? 'extraLarge' : 'medium'} loadPhoto={loadPhoto}>
        {user.avatar}
      </Avatar>
      <Box gap="12" display="flex" flexDirection="column">
        <Typography variant="h6" color="text.light.primary">
          {user.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.light.secondary"
        >{`Дата рождения: ${user.birthday}`}</Typography>
      </Box>
    </Box>
  );
};
