import React, { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import {
  AttachFile as AttachFileIcon,
  Help as HelpIcon,
} from '@material-ui/icons';

import { Box } from '@vk-hr-tek/ui/Box';
import { FilePreview } from '@vk-hr-tek/ui/FilePreview';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useDispatch } from '@app/hooks';

import { Page } from '../../ui/page';
import { UserApiService } from '../../services';
import { ReactComponent as GosuslugiLogo } from '../../../../../../public/icons/gosuslugi-logo.svg';
import { pollUser } from '../../slice';
import { selectCertificateReleaseMethod } from '../../slice';
import { PageError } from '../../../layout';

export const CertificateConfirmation = () => {
  const service = useInject(UserApiService);
  const dispatch = useDispatch();
  const releaseMethod = useSelector(selectCertificateReleaseMethod);

  const loadUnep = useCallback(async () => {
    const res = await service.getUnep();
    return res.file;
  }, [service]);

  useEffect(() => {
    dispatch(pollUser());
  }, [dispatch]);

  return (
    <>
      {releaseMethod === 'esia' ? (
        <Page title="Выпуск электронной подписи">
          <Box>
            <Box
              mb="8"
              px="24"
              py="16"
              bgcolor="bg.greyscale.primary"
              radius="l"
            >
              <FilePreview
                onLoad={loadUnep}
                view="link"
                isClickable
                showSnackbarOnDesktop
                snackbarMessage="Заявление на выдачу УНЭП"
                snackbarAction="Ok"
                linkText={
                  <Box display="flex" alignItems="center">
                    <AttachFileIcon />
                    <Box ml="8" display="inline">
                      Заявление на выдачу электронной подписи
                    </Box>
                  </Box>
                }
              />
            </Box>
            <Box mb="16">
              <Typography variant="body3" color="text.light.secondary">
                Вам отправлено уведомление на портал Госуслуг. Чтобы продолжить
                выпуск электронной подписи, подтвердите свою личность, перейдя
                по ссылке из этого уведомления.
              </Typography>
            </Box>
            <Box mb="16">
              <Typography variant="body3" color="text.light.secondary">
                После получение подтверждения выпуск электронной подписи
                продолжится автоматически.
              </Typography>
            </Box>

            <Box p="24" bgcolor="bg.greyscale.primary" radius="l">
              <Typography variant="h6">Инструкция:</Typography>
              <Box display="flex" alignItems="center" pt="12" pb="8">
                <Box
                  bgcolor="stroke.primary"
                  radius="s"
                  height={20}
                  width={20}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mr="8"
                >
                  1
                </Box>
                <Typography variant="body3" color="text.light.secondary">
                  Перейти на портал Госуслуги
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" py="8">
                <Box
                  bgcolor="stroke.primary"
                  radius="s"
                  height={20}
                  width={20}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mr="8"
                >
                  2
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="body3"
                    color="text.light.secondary"
                    component="span"
                  >
                    Проверить, что у вас{' '}
                    <Link
                      size="small"
                      href="https://www.gosuslugi.ru/help/faq/popular/1"
                      target="_blank"
                      stroke={false}
                      variant="simple"
                    >
                      подтвержденная учетная запись
                    </Link>
                  </Typography>
                  <Box ml="4" display="inline">
                    <Tooltip title="Ваш аккаунт на Госуслугах должен быть полностью подтвержден. Посмотреть статус аккаунта можно в разделе Профиль &rarr; Учетная запись">
                      <HelpIcon color="disabled" fontSize="small" />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" py="8">
                <Box
                  bgcolor="stroke.primary"
                  radius="s"
                  height={20}
                  width={20}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mr="8"
                >
                  3
                </Box>
                <Typography variant="body3" color="text.light.secondary">
                  Зайти в уведомления
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" py="8">
                <Box
                  bgcolor="stroke.primary"
                  radius="s"
                  height={20}
                  width={20}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mr="8"
                >
                  4
                </Box>
                <Typography variant="subtitle2" color="text.light.secondary">
                  <b>Найти уведомление от «СКБ Контур»</b>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" pt="8">
                <Box
                  bgcolor="stroke.primary"
                  radius="s"
                  height={20}
                  width={20}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mr="8"
                >
                  5
                </Box>
                <Typography variant="body3" color="text.light.secondary">
                  Подтвердить, что это вы выпускаете электронную подпись
                </Typography>
              </Box>
            </Box>

            <Box mb="32">
              <Typography variant="body3" color="text.light.secondary">
                Ссылка в уведомлении будет действовать 24 часа
              </Typography>
            </Box>

            <Box>
              <Link
                href="https://www.gosuslugi.ru/"
                target="_blank"
                size="small"
                variant="simple"
              >
                <Box
                  border={2}
                  borderColor="stroke.primary"
                  radius="l"
                  p="8"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                >
                  <GosuslugiLogo />
                </Box>
              </Link>
            </Box>
          </Box>
        </Page>
      ) : (
        <PageError status={404} />
      )}
    </>
  );
};
