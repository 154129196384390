/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';

import classNames from 'classnames';
import useAutocomplete, {
  createFilterOptions,
} from '@material-ui/lab/useAutocomplete';
import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Box } from '../../Box';
import { CancelIcon } from '../../icons';
import { Label, Preloader } from '../common';
import { Paper } from '../../Paper';
import { TreeView } from '../../TreeView';
import { Typography } from '../../Typography';
import { CircularProgress } from '../../CircularProgress';

import { ListBox } from './ListBox';
import { useStyles } from './TreeViewAutocomplete.styles';

const MIN_INPUT_VALUE_LENGTH = 3;

type TreeNode<T> = {
  children?: Option<T>[];
};

interface Filter {
  limit: number;
  offset: number;
  query: string;
}

type Option<T> = Omit<T, 'children'> & TreeNode<T>;

interface TreeViewAutocompleteProps<T> {
  disabled?: boolean;
  name?: string;
  label: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  options: Option<T>[];
  noOptionsText?: string;
  placeholder?: string;
  clearable?: boolean;
  limit?: number;
  getOptionLabel?: (option: T) => string;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  onSearch?: (filter: Filter) => Promise<T[]>;
  onChildrenLoad?: (unitId: string) => Promise<T[]>;
  onLoadTree?: (unitId: string) => Promise<Option<T>[]>;
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  testId?: string;
}

export const TreeViewAutocomplete = <T extends { id: string; label: string }>({
  disabled = false,
  label,
  tooltip,
  options,
  placeholder = '',
  clearable = true,
  required = false,
  getOptionLabel = (option) => option.label || '',
  noOptionsText = 'Ничего не найдено',
  onChange,
  onSearch,
  onChildrenLoad,
  onLoadTree,
  value,
  error = false,
  helperText = '',
  loading = false,
  testId = 'test-id-tree-view-autocomplete',
  limit = 50,
  ...rest
}: TreeViewAutocompleteProps<T>) => {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [isPaginationLoading, setPaginationLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [unitTree, setUnitTree] = useState<Option<T>[]>(options);
  const [loadedOptions, setLoadedOptions] = useState<T[]>([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [cachedValue, setCachedValue] = useState('');
  const [filter, setFilter] = useState<Filter>({
    limit,
    offset: 0,
    query: '',
  });

  const isDisabled = disabled || loading;

  const treeOpen = !isDisabled && open;

  useEffect(() => {
    if (treeOpen && onLoadTree && value) {
      setLoading(true);
      onLoadTree(value)
        .then((result) => {
          setUnitTree(result);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [onLoadTree, treeOpen, value]);

  const handleChange = useCallback(
    (nextValue: string | undefined) => {
      onChange(nextValue);
      setOpen(false);
    },
    [onChange],
  );

  const handleClearClick = useCallback(
    (e) => {
      if (!focused) {
        e.preventDefault();
        e.stopPropagation();
      }
      setInputValue('');
      setCachedValue('');
      onChange('');
    },
    [focused, onChange],
  );

  const handleArrowClick = useCallback(() => {
    if (!treeOpen) {
      anchorEl?.focus();
    }
  }, [anchorEl, treeOpen]);

  const getFlatTree = useCallback((array: Option<T>[]) => {
    let result: T[] = [];
    array.forEach((item) => {
      if (item.children instanceof Array) {
        const { children, ...itemRest } = item;
        result.push(itemRest as T);
        result = [...result, ...getFlatTree(children)];
      } else {
        result.push(item as T);
      }
    });
    return result;
  }, []);

  const flatOptions = useMemo(
    () => getFlatTree(options).sort((a, b) => (a.label > b.label ? 1 : -1)),
    [getFlatTree, options],
  );

  useEffect(() => {
    setUnitTree(options);
  }, [options]);

  useEffect(() => {
    if (value) {
      setInputValue(flatOptions.find((opt) => opt.id === value)?.label || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = useCallback(
    (newValue: string) => {
      if (newValue && onSearch) {
        setLoading(true);
        setFilter({
          offset: 0,
          limit: 50,
          query: '',
        });
        setHasMore(true);
        onSearch({
          query: newValue,
          limit: 50,
          offset: 0,
        })
          .then((result) => {
            setLoadedOptions(result);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    },
    [onSearch],
  );

  useEffect(() => {
    if (!value) {
      setInputValue('');
      setCachedValue('');
      setLoadedOptions([]);
    }
  }, [value]);

  const { getRootProps, getInputProps, groupedOptions, getOptionProps } =
    useAutocomplete({
      id: 'tree-view-autocomplete',
      options: onSearch ? loadedOptions : flatOptions,
      value: (value as any) || null,
      clearOnBlur: false,
      getOptionLabel,
      blurOnSelect: true,
      getOptionSelected: (option) => option.id === value,
      inputValue,
      onChange: (event, val) => {
        handleChange(val ? val.id : '');
        setInputValue(val ? val.label : '');
        setCachedValue(val ? val.label : '');
      },
      filterOptions: createFilterOptions<T>({ ignoreAccents: false }),
      onInputChange: (event, nextValue, reason) => {
        if (reason !== 'reset') {
          handleSearch?.(nextValue);
          setInputValue(nextValue);
        }

        if (nextValue !== '') {
          setOpen(true);
        }
      },
    });

  const popperRef = useRef<null | HTMLDivElement>(null);
  const isPopperOpen =
    treeOpen && (!inputValue || inputValue.length >= MIN_INPUT_VALUE_LENGTH);

  const getHighlightedLabel = useCallback(
    (currentValue: string) => {
      const words = currentValue.split(new RegExp(`(${inputValue})`, 'gi'));

      return (
        <span>
          {words.map((part, i) => (
            <Typography
              component="span"
              color={
                part.toLowerCase() === inputValue.toLowerCase()
                  ? 'text.light.primary'
                  : 'text.light.tertirary'
              }
              key={i}
            >
              {part}
            </Typography>
          ))}
        </span>
      );
    },
    [inputValue],
  );

  const handleNext = () => {
    if (!onSearch) return;

    const newFilter = {
      ...filter,
      query: inputValue,
      offset: filter.offset + filter.limit,
    };

    setPaginationLoading(true);
    setFilter(newFilter);
    onSearch(newFilter)
      .then((result) => {
        setLoadedOptions([...loadedOptions, ...result]);
        setPaginationLoading(false);
        setHasMore(!!result.length);
      })
      .catch(() => setPaginationLoading(false));
  };

  const onFocus = useCallback(() => {
    setFocused(true);
    setInputValue('');
    setCachedValue(inputValue);
  }, [inputValue]);

  const onClick = useCallback(() => setOpen((prev) => !prev), []);

  const onBlur = useCallback(
    (event) => {
      const { relatedTarget } = event;
      const containsTarget = popperRef?.current?.contains(
        relatedTarget as Element,
      );
      if (!containsTarget) {
        setOpen(false);
      }
      setFocused(false);

      setInputValue(cachedValue);
    },
    [cachedValue],
  );

  const onTreeViewSelect = useCallback(
    (id, newLabel) => {
      handleChange(id);
      setInputValue(newLabel);
      setCachedValue(newLabel);
      setTimeout(() => {
        anchorEl?.blur();
      }, 0);
    },
    [handleChange, anchorEl],
  );

  const isShowNoOptionText = !isLoading && groupedOptions.length === 0;
  const isShowMenuOptions = !isLoading && groupedOptions.length !== 0;

  return (
    <>
      <div {...getRootProps()}>
        <FormControl
          className={classNames(
            classes.treeViewAutocompleteInput,
            'aqa_tree_view_autocomplete_input',
          )}
        >
          <Label label={label} required={required} tooltip={tooltip} />
          <TextField
            data-testid={testId}
            {...rest}
            {...getInputProps()}
            disabled={isDisabled}
            fullWidth
            variant="outlined"
            autoComplete="off"
            error={error}
            helperText={helperText}
            placeholder={placeholder || label}
            onFocus={onFocus}
            onClick={onClick}
            value={loading ? 'Загрузка...' : inputValue}
            onBlur={onBlur}
            inputProps={{
              className: classes.input,
              ref: setAnchorEl,
            }}
            InputProps={{
              className: `${classes.inputRoot} aqa_select`,
              disabled: isDisabled,
              endAdornment: loading ? (
                <Preloader />
              ) : (
                <InputAdornment className={classes.endAdornment} position="end">
                  {inputValue && (focused || clearable) && (
                    <Box
                      className={classes.clearButton}
                      onClick={handleClearClick}
                    >
                      <CancelIcon color="disabled" />
                    </Box>
                  )}
                  <Box
                    className={classes.arrowButton}
                    onClick={handleArrowClick}
                  >
                    <ArrowDropDownIcon
                      className={classNames({
                        [classes.popupIndicatorOpen]: treeOpen,
                      })}
                    />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Popper
          role="presentation"
          open={isPopperOpen}
          ref={popperRef}
          anchorEl={anchorEl}
          style={{
            width: anchorEl ? anchorEl.clientWidth : 'auto',
            zIndex: 1,
          }}
          modifiers={{ offset: { offset: '0, 5' } }}
        >
          <Paper elevation={2} className={classes.paper}>
            <Box py="8">
              {inputValue ? (
                <>
                  {isLoading && (
                    <Box display="flex" justifyContent="center" p="16">
                      <CircularProgress size={30} />
                    </Box>
                  )}

                  {isShowMenuOptions && (
                    <ListBox
                      hasMore={hasMore}
                      dataLength={groupedOptions.length}
                      next={handleNext}
                      loading={isPaginationLoading}
                    >
                      {groupedOptions.map((option, index) => (
                        <MenuItem
                          key={option.id}
                          className={classes.menuItem}
                          {...getOptionProps({ option, index })}
                        >
                          {getHighlightedLabel(getOptionLabel(option))}
                        </MenuItem>
                      ))}
                    </ListBox>
                  )}

                  {isShowNoOptionText && (
                    <Box className={classes.menuItem}>
                      <Typography color="text.light.tertirary">
                        {noOptionsText}
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {isLoading && (
                    <Box display="flex" justifyContent="center" p="16">
                      <CircularProgress size={30} />
                    </Box>
                  )}
                  {!isLoading && (
                    <TreeView
                      items={unitTree}
                      selected={value || ''}
                      selectOnShrink={false}
                      showRulers={false}
                      showCounters={false}
                      onSelect={onTreeViewSelect}
                      onChildrenLoad={onChildrenLoad}
                    />
                  )}
                </>
              )}
            </Box>
          </Paper>
        </Popper>
      </div>
    </>
  );
};
