import React from 'react';

import { Form } from 'react-final-form';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import { Box } from '@vk-hr-tek/ui/Box';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { MultipleRadioInput } from '@vk-hr-tek/ui/form';
import { Button } from '@vk-hr-tek/ui/Button';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useInject } from '@vk-hr-tek/core/ioc';
import { useQuery } from '@vk-hr-tek/core/query';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { SubmitError } from '@vk-hr-tek/ui/form';
import { Page } from '@vk-hr-tek/app/layout';
import { Paper } from '@vk-hr-tek/ui/Paper';

import { useSelector, useDispatch } from '../../../../app/hooks';
import {
  selectUnitError,
  selectUnit,
  selectUnitStatus,
  selectCompanyEventTypes,
  updateCompanyUnit,
} from '../../../../user';
import { ApprovalParamsDto, UpdateCompanyUnitDto } from '../../../dto';

import { ApprovalSettingsTitle } from './ApprovalSettingsTitle';

export const ApprovalSettings = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const [filter] = useQuery<ApprovalParamsDto>(ApprovalParamsDto);

  const unit = useSelector(selectUnit);
  const eventTypes = useSelector(selectCompanyEventTypes);

  const initialValues = {
    unitId: filter.unitId,
    companyId: filter.companyId,
    name: unit?.name || '',
    managerSettings:
      unit && unit.manager_settings
        ? unit.manager_settings.map((settings) => ({
            id: settings.event_type.id,
            value: !settings.direct_only,
          }))
        : [],
    ...(unit && unit.parent_id ? { parentId: unit.parent_id } : {}),
    ...(unit && unit.manager ? { managerId: unit.manager.id } : {}),
  };

  const eventTypesOptions = eventTypes.map((eventType) => ({
    id: eventType.id,
    label: eventType.name,
  }));

  const onSubmit = (values: UpdateCompanyUnitDto) =>
    new Promise((resolve) =>
      dispatch(
        updateCompanyUnit({
          values,
          actions: { resolve, reject: resolve },
        }),
      ),
    );

  const status = useSelector(selectUnitStatus);
  const error = useSelector(selectUnitError);

  if (!filter.employeeId || !filter.companyId || !filter.unitId) {
    return null;
  }

  return (
    <Page
      status={status}
      error={error}
      title={<ApprovalSettingsTitle companyId={filter.companyId} />}
    >
      <Paper>
        <Box p="32">
          <Form
            onSubmit={onSubmit}
            validate={(values) =>
              validator.validate(values, UpdateCompanyUnitDto)
            }
            initialValues={initialValues}
            keepDirtyOnReinitialize
            render={({
              handleSubmit,
              submitting,
              hasValidationErrors,
              dirtySinceLastSubmit,
              submitError,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Box pb="8">
                        <Typography variant="h6">Общая информация</Typography>
                      </Box>
                      <Box
                        p="16"
                        mb="32"
                        radius="l"
                        bgcolor="bg.greyscale.secondary"
                      >
                        <Grid container spacing="8">
                          <Grid item xs={3}>
                            <Box mr="8">
                              <Typography
                                variant="body2"
                                color="text.light.secondary"
                              >
                                Юрлицо:
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="subtitle1">
                              {unit?.manager?.company.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Box mr="8">
                              <Typography
                                variant="body2"
                                color="text.light.secondary"
                              >
                                Подразделение:
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="subtitle1">
                              {unit?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing="20">
                    <Grid item xs={12} md={8}>
                      <Box pb="8">
                        <MultipleRadioInput
                          name="managerSettings"
                          label="Согласование по бизнес-процессам"
                          trueLabel="Всех"
                          falseLabel="Только прямых"
                          options={eventTypesOptions}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        p="16"
                        mt="72"
                        radius="l"
                        bgcolor="bg.greyscale.secondary"
                      >
                        <Box mb="8">
                          <InfoOutlinedIcon color="primary" fontSize="small" />
                        </Box>
                        <Box>
                          Руководитель может согласовывать заявки всех
                          работников своего и дочерних подразделений или только
                          заявки прямых подчиненных
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    size="large"
                    disabled={submitting || hasValidationErrors}
                  >
                    <Box px="72">Сохранить</Box>
                  </Button>
                  {!dirtySinceLastSubmit && submitError && (
                    <Box mt="8">
                      <SubmitError submitError={submitError} />
                    </Box>
                  )}
                </form>
              );
            }}
          />
        </Box>
      </Paper>
    </Page>
  );
};
