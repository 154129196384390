import React, { useCallback, useEffect, useState } from 'react';

import { Form } from 'react-final-form';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { Content } from '@vk-hr-tek/ui/Content';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { Button } from '@vk-hr-tek/ui/Button';
import { CheckboxList } from '@vk-hr-tek/ui/CheckboxList';
import { renderTwoLineOptionEllipsis } from '@vk-hr-tek/ui/input';
import { AutocompleteInput, SubmitError } from '@vk-hr-tek/ui/form';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { UserApiService } from '@vk-hr-tek/app/user/services';

import { useDispatch, useSelector } from '@app/hooks';

import { Page } from '../../../../layout';
import {
  addSubstitute,
  selectCompanyEventTypes,
  selectCompanyEventTypesError,
  selectCompanyEventTypesStatus,
  selectSubstitutes,
} from '../../../slice';
import { UserRouter } from '../../../types';

import { SubstitutesHeader } from './SubstitutesHeader';

interface SubstitutesProps {
  companyId: string;
  employeeId: string;
}

export const Substitutes = ({ companyId, employeeId }: SubstitutesProps) => {
  const router = useInject<UserRouter>(UserRouter);
  const userApiService = useInject(UserApiService);

  const companyEventTypes = useSelector(selectCompanyEventTypes);
  const companyEventTypesStatus = useSelector(selectCompanyEventTypesStatus);
  const companyEventTypesError = useSelector(selectCompanyEventTypesError);

  const [substituteId, setSubstituteId] = useState('');
  const [selected, setSelected] = React.useState<string[]>([]);

  const substitutes = useSelector(selectSubstitutes);
  const dispatch = useDispatch();

  const loadAccountSubstitutes = useCallback(
    async (filter: { limit: number; offset: number; query: string }) => {
      const res = await userApiService.getCompanyEmployeeSubstitutes({
        companyId,
        employeeId,
        ...filter,
      });

      const loadedOptions = res.employees.map((employee) => ({
        label: `${employee.name}, ${employee.company.name} (${employee.personnel_number})`,
        value: employee.id,
      }));

      return loadedOptions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    setSelected(companyEventTypes.map((eventType) => eventType.id));
  }, [companyEventTypes]);

  const companyEventTypesOption = companyEventTypes.map((eventType) => ({
    label: eventType.name,
    value: eventType.id,
  }));

  const companyEmployeeSubstitutes = substitutes.find(
    ({ company, employee: companyEmployee }) =>
      company.id === companyId && companyEmployee.id === employeeId,
  );

  const companyName = companyEmployeeSubstitutes?.company.name || '';
  const employee = companyEmployeeSubstitutes?.employee;

  if (!employee) {
    router.goToProfile();
    return null;
  }

  const onSubmit = () =>
    new Promise((resolve) => {
      const goBack = (value: unknown) => {
        resolve(value);
        router.goToProfile();
      };

      dispatch(
        addSubstitute({
          values: {
            employeeId,
            eventTypes: selected,
            substituteId,
          },
          actions: {
            resolve: goBack,
            reject: goBack,
          },
        }),
      );
    });

  return (
    <Page
      error={companyEventTypesError}
      title=""
      showTitleMobile
      status={companyEventTypesStatus}
    >
      <Box mb="16">
        <SubstitutesHeader />
      </Box>
      <Content>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          p="32"
          gap="32"
        >
          <Form
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              dirtySinceLastSubmit,
              submitting,
              submitError,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing="20">
                    <Grid item xs={8}>
                      <Box mt="8">
                        <Typography variant="h6">Общая информация:</Typography>
                      </Box>
                      <Box
                        p="16"
                        mt="32"
                        radius="l"
                        bgcolor="bg.greyscale.primary"
                      >
                        <Grid container spacing="8">
                          <Grid item xs={2}>
                            <Box mr="8">
                              <Typography
                                variant="body2"
                                color="text.light.secondary"
                              >
                                Юрлицо:
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography variant="subtitle1">
                              {companyName}
                            </Typography>
                          </Grid>
                        </Grid>
                        {employee?.showPosition && employee.position && (
                          <>
                            <Grid container spacing="8">
                              <Grid item xs={2}>
                                <Box mr="8">
                                  <Typography
                                    variant="body2"
                                    color="text.light.secondary"
                                  >
                                    Должность:
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={10}>
                                <Typography variant="subtitle1">
                                  {employee.position}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing="8">
                              <Grid item xs={2}>
                                <Box mr="8">
                                  <Typography
                                    variant="body2"
                                    color="text.light.secondary"
                                  >
                                    Табельный:
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={10}>
                                <Typography variant="subtitle1">
                                  {employee.personalNumber}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Box>
                      <Box mt="32">
                        <AutocompleteInput
                          name="substituteId"
                          autocomplete
                          required
                          label="Выберите заместителя"
                          loadItems={loadAccountSubstitutes}
                          onChange={(value) => {
                            setSubstituteId(value as string);
                          }}
                          renderOption={renderTwoLineOptionEllipsis}
                          minInputValueLength={3}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing="20">
                    <Grid item xs={8}>
                      <Box mt="8">
                        <CheckboxList
                          title="Типы заявок"
                          items={companyEventTypesOption}
                          selected={selected}
                          onSelect={setSelected}
                        />
                      </Box>
                      {!dirtySinceLastSubmit && submitError && (
                        <Box pt="24">
                          <SubmitError submitError={submitError} />
                        </Box>
                      )}
                      <Box mt="32" width={250}>
                        <Button
                          disabled={!substituteId || selected.length === 0}
                          fullWidth
                          loading={submitting}
                          size="large"
                          type="submit"
                        >
                          Сохранить
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        p="16"
                        mt="48"
                        radius="l"
                        bgcolor="bg.greyscale.primary"
                      >
                        <Box mb="8">
                          <InfoOutlinedIcon color="primary" fontSize="small" />
                        </Box>
                        <Box>
                          Заместителю доступны все действия руководителя,
                          которого он замещает, в выбранных типах заявок
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </>
            )}
          />
        </Box>
      </Content>
    </Page>
  );
};
