import React, {
  ReactNode,
  useState,
  useEffect,
  KeyboardEvent,
  MouseEvent,
} from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Fade, Menu } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Link } from '@vk-hr-tek/ui/Link';
import { ExpandMoreIcon } from '@vk-hr-tek/ui/icons';

import { AppAbilities } from '@app/ability/ability';

import {
  TIMEOUT_FOR_FADE,
  TIMEOUT_FOR_SUBMENU_HOVER,
  ITEM_HEIGHT,
} from '../Sidebar.constants';

import { useStyles } from './SidebarItemsWithSubmenu.styles';

interface SidebarItemsWithSubmenuProps {
  icon: ReactNode;
  title: string;
  sidebarOpened: boolean;
  isActive: boolean;
  maxItemsInMenu?: number;
  closeDrawer?: (event: KeyboardEvent | MouseEvent) => void;
  isMobile?: boolean;
  skeletonLoading: boolean;
  aqaClass: string;
  setSubmenusVisibleItemsCount: (count: number) => void;
  role: string;
  submenu: {
    aqaClass: string;
    title: string;
    href: string;
    resource: AppAbilities[1];
  }[];
}

export const SidebarItemsWithSubmenu = ({
  icon,
  title,
  sidebarOpened,
  submenu,
  isActive,
  skeletonLoading,
  closeDrawer,
  aqaClass,
  isMobile = false,
  role,
  setSubmenusVisibleItemsCount,
}: SidebarItemsWithSubmenuProps) => {
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  const submenuHeight = expanded ? submenu.length * ITEM_HEIGHT : 0;

  const styles = useStyles({
    submenuHeight,
    expanded,
  });

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderAqaClass = (className: string): string =>
    `${aqaClass}_${className}`;

  const handleIsActive = (
    path: string,
    href: string,
    userRole: string,
  ): boolean => {
    if (href === `/${userRole}/events`) {
      return (
        path === href ||
        (path.includes(`/${userRole}/events`) && !path.includes('/paper'))
      );
    } else if (href === `/${userRole}/settings`) {
      return path === href || path.includes('/settings/event-types');
    } else if (href === `/${userRole}/settings/attorneys`) {
      return (
        path === href ||
        (path.includes('/settings/attorneys') &&
          !path.includes('/attorneys-users'))
      );
    } else {
      return path === href || path.includes(href);
    }
  };

  const handleExpandInnerMenu = () => {
    setExpanded((prev) => !prev);

    if (!expanded) {
      setSubmenusVisibleItemsCount(submenu.length);
    } else {
      setSubmenusVisibleItemsCount(-submenu.length);
    }
  };

  const handleOpenMenu = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseHover = () => {
    setTimeout(() => {
      handleCloseMenu();
    }, TIMEOUT_FOR_SUBMENU_HOVER);
  };

  const open = Boolean(anchorEl);

  const hoverOnMenuItem = !sidebarOpened ? handleOpenMenu : undefined;

  const activeStyleWhenSidebarIsClose =
    (!expanded || !sidebarOpened) && isActive && styles.active;

  useEffect(() => {
    if (sidebarOpened && isActive) {
      setExpanded(true);
      setSubmenusVisibleItemsCount(submenu.length);
    } else {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarOpened]);

  return (
    <Box
      className={styles.menuItemWithSubmenu}
      onMouseEnter={hoverOnMenuItem}
      onMouseLeave={handleCloseHover}
    >
      <Box
        className={classNames(
          styles.menuItem,
          activeStyleWhenSidebarIsClose,
          renderAqaClass('menuItemWithSubmenu'),
        )}
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={sidebarOpened ? handleExpandInnerMenu : hoverOnMenuItem}
      >
        <Box
          minWidth={24}
          minHeight={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="aqa_menuItemIcon"
        >
          {skeletonLoading ? <Skeleton width="100%" /> : icon}
        </Box>

        {!sidebarOpened && (
          <Fade in={!sidebarOpened} timeout={TIMEOUT_FOR_FADE}>
            <Box
              className={classNames(
                styles.absoluteIcon,
                renderAqaClass('absoluteIcon'),
              )}
            >
              <ExpandMoreIcon size="micro" />
            </Box>
          </Fade>
        )}

        <Fade in={sidebarOpened} timeout={TIMEOUT_FOR_FADE}>
          <Typography
            variant="body3"
            className={renderAqaClass('menuItemText')}
          >
            {skeletonLoading ? <Skeleton width={200} /> : title}
          </Typography>
        </Fade>

        {sidebarOpened && (
          <Box
            ml="auto"
            display="flex"
            alignItems="center"
            className={classNames(
              styles.menuItemExpandIcon,
              renderAqaClass('menuItemExpandIcon'),
            )}
          >
            <ExpandMoreIcon size="small" />
          </Box>
        )}
      </Box>

      <Box
        className={classNames(
          styles.dropdownMenu,
          renderAqaClass('dropdownMenu'),
        )}
      >
        {submenu.map(
          ({ title: submenuTitle, href, aqaClass: aqaSubmenuClass }) => (
            <Link
              to={href}
              variant="simple"
              key={submenuTitle}
              onClick={closeDrawer}
            >
              <Box
                className={classNames(
                  styles.submenuItem,
                  handleIsActive(pathname, href, role) && styles.active,
                  aqaSubmenuClass,
                )}
              >
                <Typography variant="body3" noWrap>
                  {submenuTitle}
                </Typography>
              </Box>
            </Link>
          ),
        )}
      </Box>

      {!sidebarOpened && !isMobile && (
        <Menu
          id="simple-menu"
          className={classNames(styles.submenu, renderAqaClass('popupSubmenu'))}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PopoverClasses={{
            root: styles.popOverRoot,
          }}
        >
          {submenu.map(
            ({ title: submenuTitle, href, aqaClass: aqaSubmenuClass }) => (
              <Link to={href} variant="simple">
                <Box
                  className={classNames(styles.popupMenuItem, aqaSubmenuClass)}
                  onClick={handleCloseMenu}
                  key={submenuTitle}
                >
                  <Typography
                    variant="body3"
                    color={
                      handleIsActive(pathname, href, role)
                        ? 'original.brand.primary'
                        : 'text.light.primary'
                    }
                  >
                    {submenuTitle}
                  </Typography>
                </Box>
              </Link>
            ),
          )}
        </Menu>
      )}
    </Box>
  );
};
