import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { DotIcon } from '@vk-hr-tek/ui/icons';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { AttorneyType } from '@app/types';

export const Attorney = ({
  issuer,
  readableStatus,
  validFrom,
  validTo,
  typographyColor,
}: AttorneyType) => (
  <Box py="16">
    <Box mb="4">
      <Typography variant="body3">{issuer}</Typography>
    </Box>
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Box mr="2">
        <Typography variant="body3" color={typographyColor}>
          {readableStatus}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mr="2">
        <DotIcon color="disabled" />
      </Box>
      <Box>
        <Typography variant="body3" color="text.light.tertirary">
          Срок действия: с {validFrom} по {validTo}
        </Typography>
      </Box>
    </Box>
  </Box>
);
