import React from 'react';

import {
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from '@material-ui/core';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core';
import { get } from 'lodash';

import { Tokens } from '../Theme';

import { useStyles } from './Typography.styles';

type DeepKeys<T extends unknown> = T extends string | symbol | number
  ? never
  : {
      [K in keyof T]: K extends string | number
        ? DeepKeys<T[K]> extends never
          ? K
          : `${K}.${DeepKeys<T[K]>}`
        : never;
    }[keyof T];

export type TypographyColor =
  | DeepKeys<Tokens['colors']>
  | 'inherit'
  | undefined;

type TypographyProps = Omit<
  MUITypographyProps,
  'variant' | 'color' | 'component'
> & {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  color?: TypographyColor;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
};

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'div',
  body2: 'div',
};

export const Typography = ({
  variant = 'body1',
  color = 'text.light.primary',
  className,
  ...rest
}: TypographyProps) => {
  const theme = useTheme();
  const { body3, ...classes } = useStyles();

  return (
    <MUITypography
      {...rest}
      variant={variant !== 'body3' ? variant : 'body2'}
      color="inherit"
      variantMapping={variantMapping}
      classes={classes}
      className={classNames(className, {
        [body3]: variant === 'body3',
        [`aqa_${variant}`]: true,
      })}
      style={{
        color:
          color === 'inherit' ? 'inherit' : get(theme.tokens.colors, color),
      }}
    />
  );
};
