import React, { useCallback } from 'react';

import { useParams, useLocation } from 'react-router-dom';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';
import { Content } from '@vk-hr-tek/ui/Content';
import { Divider } from '@vk-hr-tek/ui/Divider';
import { Grid } from '@vk-hr-tek/ui/Grid';
import {
  ContentCopyIcon,
  DotIcon,
  DownloadFileIcon,
} from '@vk-hr-tek/ui/icons';
import { IconButton } from '@vk-hr-tek/ui/IconButton';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

import { useSelector, useDispatch } from '@app/hooks';
import { AttorneyType, AttorneyStatus } from '@app/types';

import { BackLink, ContentHeader, Page, PageError } from '../../../layout';
import {
  exportAttorney,
  selectUser,
  selectStatus,
  selectError,
} from '../../slice';
import { ExportAttorneyDto } from '../../dto';
import { UserRouter } from '../../types';

type AttorneyKeys = {
  [key in keyof AttorneyType]: string;
};

const attorneyKey: AttorneyKeys = {
  id: 'ID',
  UID: 'UID',
  status: 'Статус',
  readableStatus: 'Статус',
  issuer: 'Выпущена',
  isDefault: 'Активна',
  creationDate: 'Дата создания',
  validFrom: 'Срок действия с',
  validTo: 'Срок действия по',
  companyId: 'ID Компании',
  companyName: 'ЮрЛицо',
  powers: 'Полномочия',
  revocationReason: 'Причина отзыва',
  imported: 'Импортирован',
  fullName: 'ФИО Доверенного лица',
  snils: 'СНИЛС',
  typographyColor: '',
  chipColor: '',
};

type AttorneyScheme = {
  [key in AttorneyStatus]: (keyof AttorneyType)[];
};

const attorneyScheme: AttorneyScheme = {
  ['new']: ['companyName', 'powers', 'creationDate', 'UID'],
  ['signed']: ['companyName', 'powers', 'creationDate', 'UID'],
  ['revoking']: [
    'companyName',
    'powers',
    'revocationReason',
    'creationDate',
    'UID',
  ],
  ['revoked']: [
    'companyName',
    'powers',
    'revocationReason',
    'creationDate',
    'UID',
  ],
  ['expired']: ['companyName', 'powers', 'creationDate', 'UID'],
  ['canceled']: ['companyName', 'powers', 'creationDate', 'UID'],
};

export const AttorneyDetail = () => {
  const { companyId, id } = useParams<{ id: string; companyId: string }>();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const user = useSelector(selectUser);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);

  const location =
    useLocation<{ prev?: { pathname: string; search: string }[] }>();

  const userRouter = useInject<UserRouter>(UserRouter);

  const onClick = () => {
    if (location.state?.prev) {
      userRouter.goBack(location.state.prev?.[0]);
    } else {
      userRouter.redirectToHome();
    }
  };

  const attorney = user?.attorneys.find(
    (a) => a.id === id && a.companyId === companyId,
  );

  const getProps = useCallback((attorneyStatus: AttorneyStatus) => {
    return attorneyScheme[attorneyStatus];
  }, []);

  const handleDownload = useCallback(
    (data: ExportAttorneyDto) => () => {
      dispatch(exportAttorney(data));
    },
    [dispatch],
  );

  if (!attorney) {
    return <PageError status={404} />;
  }

  const {
    id: attorneyId,
    readableStatus,
    status: attorneyStatus,
    validFrom,
    validTo,
    issuer,
    imported,
    fullName,
    snils,
    chipColor,
  } = attorney;

  const isDownloadAvailable = isDesktop && attorneyStatus !== 'new';

  return (
    <Page
      error={error}
      link={<BackLink onClick={onClick} title="Назад" />}
      showTitleMobile
      status={status}
      title="МЧД"
    >
      <Content>
        <Box px={{ xs: '16', md: '32' }}>
          <ContentHeader
            description={
              <Box>
                <Box mb="8">
                  <Typography
                    variant={isDesktop ? 'body2' : 'body3'}
                    color="text.light.tertirary"
                  >
                    СНИЛС: {snils}
                  </Typography>
                </Box>
                <Box display={isDesktop ? 'flex' : 'block'}>
                  <Box mb={{ xs: '8', md: '0' }}>
                    <Typography
                      variant={isDesktop ? 'body2' : 'body3'}
                      color="text.light.tertirary"
                    >
                      Срок действия: с {validFrom} по {validTo}
                    </Typography>
                  </Box>
                  {isDesktop && (
                    <Box mx="8" display="flex" alignItems="center">
                      <DotIcon color="disabled" />
                    </Box>
                  )}
                  <Typography
                    variant={isDesktop ? 'body2' : 'body3'}
                    color="text.light.tertirary"
                  >
                    Выпущена: {issuer}
                  </Typography>
                </Box>
              </Box>
            }
            title={fullName}
            titleActions={
              <ColoredChip label={readableStatus} color={chipColor} />
            }
          />
        </Box>
        <Box mx="-32">
          <Divider />
        </Box>
        <Box p={{ xs: '16', md: '32' }} pb="24">
          <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
            Общая информация
          </Typography>
        </Box>
        <Box p={{ xs: '16', md: '32' }} pt="0">
          <Grid container>
            {getProps(attorneyStatus).map((prop) => (
              <>
                <Grid item xs={12} md={3}>
                  <Box mb={{ xs: '8', md: '24' }}>
                    <Typography
                      variant={isDesktop ? 'body2' : 'body3'}
                      color="text.light.tertirary"
                    >
                      {prop === 'creationDate' && imported
                        ? 'Дата импорта'
                        : attorneyKey[`${prop}`] || ''}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Box mb={{ xs: '16', md: '24' }} display="flex">
                    <Box mr="8">
                      <Typography variant={isDesktop ? 'body2' : 'body3'}>
                        {attorney[`${prop}`] || '-'}
                      </Typography>
                    </Box>
                    {prop === 'UID' && (
                      <IconButton
                        type="button"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            attorney[`${prop}`] || '-',
                          );
                        }}
                      >
                        <ContentCopyIcon color="primary" size="small" />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
        {isDownloadAvailable && (
          <Box
            display="flex"
            justifyContent="flex-end"
            px="32"
            py="16"
            bgcolor="bg.greyscale.primary"
          >
            <Button
              icon={<DownloadFileIcon />}
              onClick={handleDownload({ attorneyId })}
              size="medium"
              variant="primary"
            />
          </Box>
        )}
      </Content>
    </Page>
  );
};
