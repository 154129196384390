/* eslint-disable @typescript-eslint/no-explicit-any */

import { FRONTEND_ERROR_CODE } from './constants';

export interface AppErrorOptions {
  code: number;
  message: string | string[];
  error?: string;
  data?: {
    error_code: string;
    error_details?: string;
  };
}

const errorMessages: {
  [key: string]: {
    title: string;
    description: string;
  };
} = {
  invalid_ali_link: {
    title: 'Неверная ссылка',
    description:
      'Чтобы авторизоваться <a href="/auth/qr">отсканируйте ваш QR-код</a>',
  },

  invalid_something: {
    title: 'Вы ввели неверные данные',
    description: 'Вы ввели неверные данные',
  },

  unauthorized: {
    title: 'Неверный токен',
    description: 'Неверный токен',
  },

  forbidden: {
    title: 'Нет доступа к указанному объекту',
    description: 'Нет доступа к указанному объекту',
  },

  user_is_not_in_group: {
    title: 'У пользователя нет указанной группы',
    description: 'У пользователя нет указанной группы',
  },

  user_already_in_group: {
    title: 'У пользователя уже есть указанная группа',
    description: 'У пользователя уже есть указанная группа',
  },

  user_is_not_employee: {
    title: 'У пользователя нет работника в указанной компании',
    description: 'У пользователя нет работника в указанной компании',
  },

  not_found_group: {
    title: 'Группа не найдена',
    description: 'Группа не найдена',
  },

  not_found_auth_code: {
    title: 'Не найден код авторизации',
    description: 'Не найден код авторизации',
  },

  not_found_company: {
    title: 'Компания не найдена',
    description: 'Компания не найдена',
  },

  event_not_found: {
    title: 'Заявка не найдена',
    description: 'Заявка не найдена',
  },

  wrong_invite: {
    title: 'Эта ссылка для регистрации в сервисе больше не работает.',
    description:
      'Если вам нужно новое приглашение в сервис КЭДО, обратитесь в отдел кадров или к администратору КЭДО в вашей компании.',
  },

  event_type_not_found: {
    title: 'Тип заявки не найден',
    description: 'Тип заявки не найден',
  },

  event_type_group_not_found: {
    title: 'Группа типов заявок не найдена',
    description: 'Группа типов заявок не найдена',
  },

  work_flow_node_not_found: {
    title: 'Не найден этап по внешнему идентификатору',
    description: 'Не найден этап по внешнему идентификатору',
  },

  company_not_found: {
    title: 'Компания не найдена',
    description: 'Компания не найдена',
  },

  group_not_found: {
    title: 'Группа не найдена',
    description: 'Группа не найдена',
  },

  sign_hash_failed: {
    title: 'Ошибка при получении подписи',
    description: 'Ошибка при получении подписи',
  },

  file_not_found: {
    title: 'Файл не найден. Обновите страницу и попробуйте еще раз.',
    description: 'Файл не найден. Обновите страницу и попробуйте еще раз.',
  },

  document_not_found: {
    title: 'Документ не найден',
    description: 'Документ не найден',
  },

  not_found: {
    title: 'Ошибка. Обратитесь в службу поддержки',
    description: 'Ошибка. Обратитесь в службу поддержки',
  },

  release_statement_not_found: {
    title: 'Не найдено заявление на выпуск УНЭП',
    description: 'Не найдено заявление на выпуск УНЭП',
  },

  certificate_not_ready: {
    title: 'Сертификат УНЭП в процессе создания',
    description: 'Сертификат УНЭП в процессе создания',
  },

  certificate_released: {
    title: 'Сертификат УНЭП уже выпущен',
    description: 'Сертификат УНЭП уже выпущен',
  },

  cant_download_archive: {
    title:
      'Не получилось скачать архив. Обновите страницу и попробуйте еще раз.',
    description:
      'Не получилось скачать архив. Обновите страницу и попробуйте еще раз.',
  },

  invalid_file_size: {
    title: 'Ошибка: слишком большой размер файла',
    description: 'Ошибка: слишком большой размер файла',
  },

  invalid_date_format: {
    title: 'Неверный формат даты. Правильный формат: ДД.ММ.ГГГГ',
    description: 'Неверный формат даты. Правильный формат: ДД.ММ.ГГГГ',
  },

  invalid_year_format: {
    title: 'Неверный формат года. Правильный формат: ГГГГ',
    description: 'Неверный формат года. Правильный формат: ГГГГ',
  },

  invalid_arguments: {
    title: 'Ошибка. Обратитесь в службу поддержки',
    description: 'Ошибка. Обратитесь в службу поддержки',
  },

  invalid_node: {
    title: 'Нельзя выполнить действие на этом этапе',
    description: 'Нельзя выполнить действие на этом этапе',
  },

  missing_attribute: {
    title:
      'Не хватает данных для выполнения действия на этапе. Проверьте, что все обязательные поля заполнены и попробуйте еще раз',
    description:
      'Не хватает данных для выполнения действия на этапе. Проверьте, что все обязательные поля заполнены и попробуйте еще раз',
  },

  pass_not_found: {
    title: 'Пользователь не найден',
    description: 'Пользователь не найден',
  },

  pass_invalid_snils: {
    title: 'Неверный СНИЛС',
    description: 'Неверный СНИЛС',
  },

  no_pass_employees: {
    title: 'У пользователя нет ни одного работника',
    description: 'У пользователя нет ни одного работника',
  },

  invalid_scope: {
    title: 'Неверный код из смс',
    description: 'Неверный код из смс',
  },

  does_not_match_the_side: {
    title: 'Неверный кабинет. Переключите кабинет и попробуйте еще раз',
    description: 'Неверный кабинет. Переключите кабинет и попробуйте еще раз',
  },

  no_company_access: {
    title: 'Нет доступа к указанному объекту',
    description: 'Нет доступа к указанному объекту',
  },

  policy_already_exist: {
    title: 'Политика с таким названием уже существует',
    description: 'Политика с таким названием уже существует',
  },

  policy_version_activity_range_overlaps_another_one: {
    title:
      'Ошибка в выборе дат. Даты действия версии не должны пересекаться с датами действия других версий',
    description:
      'Ошибка в выборе дат. Даты действия версии не должны пересекаться с датами действия других версий',
  },

  policy_not_found: {
    title: 'Политика не найдена',
    description: 'Политика не найдена',
  },

  policy_version_not_found: {
    title: 'Версия политики не найдена',
    description: 'Версия политики не найдена',
  },

  external_id_not_found: {
    title: 'Не найден объект с указанным внешним идентификатором',
    description: 'Не найден объект с указанным внешним идентификатором',
  },

  employee_is_dismissed: {
    title: 'Уволен работник, для которого создавалась заявка',
    description: 'Уволен работник, для которого создавалась заявка',
  },

  logo_not_found: {
    title: 'Ошибка с логотипом',
    description: 'Ошибка с логотипом',
  },

  invalid_pdf_file: {
    title:
      'Невалидный .pdf файл. Проверьте файл и попробуйте еще раз или обратитесь в службу поддержки',
    description:
      'Невалидный .pdf файл. Проверьте файл и попробуйте еще раз или обратитесь в службу поддержки',
  },

  invite_status_already_completed: {
    title: 'Неверная ссылка',
    description:
      'Чтобы получить новую ссылку, вам необходимо обратиться в отдел кадров вашей компании',
  },
  invalid_candidate_invite_code: {
    title: 'Ссылка не работает',
    description:
      'К сожалению ваша ссылка не работает. Попросите ваше контактное лицо в компании выслать вам новую ссылку.',
  },
  expired_invite: {
    title: 'Ссылка устарела',
    description:
      'К сожалению, ваша ссылка устарела. Чтобы получить новую ссылку, вам необходимо обратиться в отдел  кадров вашей компании',
  },
  invalid_password: {
    title: 'Неверный пароль',
    description: 'Вы указали неверный пароль',
  },
  invalid_phone_code: {
    title: 'Неверный код СМС',
    description: 'Вы указали неверный код СМС',
  },
  incompatible_authorized_employee_user: {
    title: 'Доступ по ссылке запрещен',
    description: 'Запросите у работодателя новую ссылку',
  },
  invalid_snils: {
    title: 'Неверный СНИЛС',
    description: 'Вы указали неверный СНИЛС',
  },
  change_phone_invite_required: {
    title: 'Смена телефона',
    description:
      'Чтобы сменить телефон, вам нужно зарегистрироваться заново. Попросите ссылку на регистрацию в отделе кадров',
  },
  user_is_not_registered: {
    title: 'Пользователь не зарегистрирован',
    description: 'Вы указали незарегистрированного пользователя',
  },
  invalid_user_phone: {
    title: 'Неверный телефон',
    description: 'Вы указали неверный номер телефона',
  },
  invalid_raw_password: {
    title: 'Неверный пароль',
    description: 'Вы указали неверный пароль',
  },
  forbidden_password: {
    title: 'Неверный пароль',
    description:
      'Этот пароль вы уже использовали раньше. Придумайте новый пароль',
  },
  invite_canceled: {
    title: 'Ссылка устарела',
    description:
      'К сожалению, ваша ссылка устарела. Чтобы получить новую ссылку, вам необходимо обратиться в отдел  кадров вашей компании',
  },
  invalid_phone_to_notify: {
    title: 'Неверный телефон',
    description: 'Вы указали неверный номер телефона',
  },
  notify_rate_limit: {
    title: 'Слишком много запросов',
    description: 'Вы превысили лимит запросов',
  },
  certificate_not_found: {
    title: 'Сертификат не найден',
    description: 'Сертификат не найден',
  },
  not_found_user: {
    title: 'Пользователь не найден',
    description: 'Вы указали несуществующего пользователя',
  },
  not_found_employee: {
    title: 'Работник не найден',
    description: 'Работник не найден',
  },
  not_found_invite: {
    title: 'Доступ по ссылке запрещен',
    description: 'Запросите у работодателя новую ссылку',
  },
  front_empty_auth_code: {
    title: 'Доступ по ссылке запрещен',
    description: 'Попробуйте авторизоваться заново',
  },
  rate_limit: {
    title: 'Превышен лимит отправки СМС',
    description: 'Повторите запрос позже',
  },
  snils_diff: {
    title: 'Разные значения СНИЛС пользователя и представителя загружаемой МЧД',
    description:
      'Разные значения СНИЛС пользователя и представителя загружаемой МЧД',
  },
  expired_attorney: {
    title: 'Срок действия загружаемой МЧД устарел',
    description: 'Срок действия загружаемой МЧД устарел',
  },
  ogrn_diff: {
    title:
      'Значения ОГРН/ОГРНИП доверителя из МЧД и ОГРН компании не совпадают',
    description:
      'Значения ОГРН/ОГРНИП доверителя из МЧД и ОГРН компании не совпадают',
  },
  sig_not_for_xml: {
    title: 'Подпись не соответствует загружаемой МЧД',
    description: 'Подпись не соответствует загружаемой МЧД',
  },

  invalid_user_data: {
    title:
      'Не хватает данных (личные данные работника) для создания документа. Обратитесь в службу поддержки.',
    description:
      'Не хватает данных (личные данные работника) для создания документа. Обратитесь в службу поддержки.',
  },

  phone_change_no_phone: {
    title:
      'Обратитесь к администраторам КЭДО в вашей компании, чтобы получить ссылку для регистрации с вашим актуальным номером телефона.',
    description:
      'Обратитесь к администраторам КЭДО в вашей компании, чтобы получить ссылку для регистрации с вашим актуальным номером телефона.',
  },
  '400': {
    title:
      'Ошибка. Попробуйте еще раз и обратитесь в службу поддержки, если ошибка повторится.',
    description: 'Вы указали неверные данные',
  },
  '401': {
    title: 'Неавторизованный запрос',
    description: 'Похоже, вы неавторизованы',
  },
  '403': {
    title: 'Неавторизованный запрос',
    description: 'У вас нет прав на выполнение операции',
  },
  '404': {
    title: 'Страница не найдена',
    description: 'Страница не найдена',
  },
  '500': {
    title: 'Что-то пошло не так. Попробуйте еще раз',
    description: 'Упс! Что-то пошло не так',
  },
};

export class AppError extends Error {
  status: number;

  source: 'client' | 'server';

  description = 'Упс! Что-то пошло не так';

  title = 'Что-то пошло не так. Попробуйте еще раз';

  info?: string | string[];

  errorCode?: string;

  traceId?: string | null;

  constructor(
    source: 'client' | 'server',
    { code, message, data }: AppErrorOptions,
    traceId?: string | null,
  ) {
    super();

    this.status = code;

    this.source = source;

    this.info = message;

    this.traceId = traceId || FRONTEND_ERROR_CODE;

    if (code >= 400 && code < 500) {
      const { title, description } =
        errorMessages[code.toString()] || errorMessages['400'];

      this.title = title;
      this.description = description;
    }

    if (data && data.error_code) {
      this.errorCode = data.error_code;

      if (errorMessages[this.errorCode]) {
        const { title, description } = errorMessages[this.errorCode];

        this.title = title;
        this.description = description;
      }
    }
  }
}
