import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Dialog } from '@vk-hr-tek/ui/Dialog';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Button } from '@vk-hr-tek/ui/Button';

import { useLogout } from '../../../auth';

type LogoutConfirmationProps = {
  onClose(): void;
  open: boolean;
};

export const LogoutConfirmation = ({
  onClose,
  open,
}: LogoutConfirmationProps) => {
  const logout = useLogout();

  return (
    <Dialog fullScreen onClose={onClose} open={open}>
      <Box height="100%" display="flex" flexDirection="column" px="20" py="32">
        <Box flexGrow={1}>
          <Box pb="8">
            <Link size="small" onClick={onClose} stroke={false}>
              Назад
            </Link>
          </Box>
          <Box mb="16">
            <Typography variant="h5" color="text.light.primary">
              Выйти из аккаунта
            </Typography>
          </Box>
          <Typography variant="body2" color="text.light.secondary">
            Вы точно хотите выйти из своей учетной записи?
          </Typography>
        </Box>
        <Box>
          <Button
            fullWidth
            size="large"
            variant="warning"
            onClick={() => logout()}
          >
            Выйти
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
