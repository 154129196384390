import React, { useCallback } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { Form } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { Content } from '@vk-hr-tek/ui/Content';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { RadioInputComplexLabel, SubmitError } from '@vk-hr-tek/ui/form';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useSelector, useDispatch } from '@app/hooks';

import { BackLink, Page, PageError } from '../../../layout';
import {
  selectUser,
  selectStatus,
  selectError,
  setDefaultAttorney,
} from '../../slice';
import { SetDefaultAttorneyDto } from '../../dto';
import { UserRouter } from '../../types';

import { Attorney } from './Attorney';
import { Callout } from './Callout';

export const AttorneySettings = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const router = useInject<UserRouter>(UserRouter);
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const user = useSelector(selectUser);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);

  const location =
    useLocation<{ prev?: { pathname: string; search: string }[] }>();

  const userRouter = useInject<UserRouter>(UserRouter);

  const onClick = () => {
    if (location.state?.prev) {
      userRouter.goBack(location.state.prev?.[0]);
    } else {
      userRouter.redirectToHome();
    }
  };

  const onSubmit = useCallback(
    (values: SetDefaultAttorneyDto) =>
      new Promise((resolve) => {
        const back = (value: unknown) => {
          resolve(value);
          router.goToProfile();
        };

        dispatch(
          setDefaultAttorney({
            values: { ...values },
            actions: { resolve: back, reject: back },
          }),
        );
      }),
    [dispatch, router],
  );

  const group = user?.groups.find((gr) => gr.id === companyId);

  const companyName = group?.name || '';

  const roles = group?.roles || [];

  const attorneysByCompanyId =
    group?.attorneys.filter(
      (attorney) =>
        attorney.status === 'signed' || attorney.status === 'revoking',
    ) || [];

  if (!attorneysByCompanyId.length) {
    return <PageError status={403} />;
  }

  const defaultAttorneyId = attorneysByCompanyId.find(
    (attorney) => attorney.isDefault,
  )?.id;

  return (
    <Page
      error={error}
      link={<BackLink onClick={onClick} title="Назад" />}
      showTitleMobile
      status={status}
      title="Настройки МЧД"
    >
      <Content>
        <Box p={{ xs: '16', md: '32' }}>
          <Form
            initialValues={{ attorneyId: defaultAttorneyId }}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitting,
              pristine,
              hasValidationErrors,
              dirtySinceLastSubmit,
              submitError,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Box mb={{ xs: '16', md: '24' }} mx={{ xs: '8', md: '0' }}>
                    <Typography variant="h6">Общая информация</Typography>
                  </Box>
                  <Box mb={{ xs: '24', md: '32' }}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Box
                          p="16"
                          radius="l"
                          border="1px solid"
                          borderColor="bg.greyscale.secondary"
                          bgcolor="bg.greyscale.primary"
                        >
                          <Grid container>
                            <Grid item xs={12} md={2}>
                              <Box mb={{ xs: '2', md: '8' }}>
                                <Typography
                                  variant="body2"
                                  color="text.light.secondary"
                                >
                                  Юрлицо:
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={10}>
                              <Box mb="8">
                                <Typography variant="subtitle1">
                                  {companyName}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <Typography
                                variant="body2"
                                color="text.light.secondary"
                              >
                                Роль:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={10}>
                              <Box display="flex">
                                <Typography variant="subtitle1">
                                  {roles.map(({ name }) => name).join(', ')}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb="16" mx={{ xs: '8', md: '0' }}>
                    <Typography variant="h6">Доступные МЧД</Typography>
                  </Box>
                  <Grid
                    container
                    direction={isDesktop ? 'row' : 'column-reverse'}
                  >
                    <Grid item xs={12} md={6}>
                      <Box
                        radius="l"
                        border="1px solid"
                        borderColor="stroke.primary"
                        px={{ xs: '16', md: '24' }}
                        mr={{ xs: '0', md: '8' }}
                      >
                        {!!attorneysByCompanyId.length && (
                          <RadioInputComplexLabel
                            name="attorneyId"
                            options={attorneysByCompanyId.map((attorney) => ({
                              label: attorney,
                              value: attorney.id,
                            }))}
                            render={(item) => <Attorney {...item} />}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Box mb={{ xs: '16', md: '0' }}>
                        <Callout>
                          <Typography variant="body2">
                            Выберите МЧД, с помощью которой будете подписывать
                            все документы в компании.
                          </Typography>
                        </Callout>
                      </Box>
                    </Grid>
                  </Grid>
                  {!dirtySinceLastSubmit && submitError && (
                    <Box pt={{ xs: '16', md: '24' }}>
                      <SubmitError submitError={submitError} />
                    </Box>
                  )}
                  <Box mt="32" mb={{ xs: '8', md: '0' }}>
                    <Button
                      disabled={pristine || hasValidationErrors}
                      fullWidth={!isDesktop}
                      loading={submitting}
                      size="large"
                      type="submit"
                    >
                      Сохранить
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
      </Content>
    </Page>
  );
};
