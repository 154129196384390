import React from 'react';

import { CompanyPositionSubstitutes } from '@vk-hr-tek/app/user';
import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

import { CompanyUnit } from './CompanyUnit';

interface CompanyUnitsProps {
  companyUnit: CompanyPositionSubstitutes;
}

export const CompanyUnits = ({ companyUnit }: CompanyUnitsProps) => {
  const isDesktop = useIsDesktop();

  if (!companyUnit.manageableUnits?.length) {
    return null;
  }

  return (
    <>
      <Box m="24">
        <Box mb="24">
          <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
            {companyUnit.company.name}
          </Typography>
        </Box>
        {companyUnit.manageableUnits.map((manageableUnit) => (
          <React.Fragment key={manageableUnit.unit.id}>
            <CompanyUnit
              employeeId={companyUnit.employee.id}
              companyId={companyUnit.company.id}
              companyUnit={manageableUnit}
            />
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};
