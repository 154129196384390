import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Button } from '@vk-hr-tek/ui/Button';
import { useQuery } from '@vk-hr-tek/core/query';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

import { Page } from '../../../ui/page';
import {
  redirectToCandidateLogin,
  setCandidateInviteCode,
} from '../../../slice';
import greeting from '../../../assets/greeting.svg';
import { CandidateLoginInitDto } from '../../../dto/candidate';
import vkHrTekLogo from '../../../assets/vk-hr-tek-logo.svg';

export const Greeting = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const [{ invite }] = useQuery(CandidateLoginInitDto);

  const authButtonHandler = useCallback(() => {
    dispatch(setCandidateInviteCode(invite));
    dispatch(redirectToCandidateLogin(invite));
  }, [dispatch, invite]);

  return (
    <Page title="">
      {!isDesktop && (
        <Box display="flex" justifyContent="center" mb="24">
          <img src={vkHrTekLogo} />
        </Box>
      )}

      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mb="40">
          <img src={greeting} />
        </Box>

        <Box mb="12">
          <Typography variant="subtitle1">Добро пожаловать!</Typography>
        </Box>

        <Box mb={{ xs: '56', md: '32' }}>
          <Typography
            variant="body2"
            color="text.light.secondary"
            align="center"
          >
            Процесс оформления пройдет полностью онлайн.
            <br />
            Вы будете получать уведомления, когда от вас будет требоваться
            какое-то действие или информация.
          </Typography>
        </Box>

        <Box width={{ xs: '100%', md: 200 }}>
          <Button fullWidth size="large" onClick={authButtonHandler}>
            Начать
          </Button>
        </Box>
      </Box>
    </Page>
  );
};
